<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Discount Codes</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Discount Codes"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              class="mr-2"
              depressed
              light
              :to="{ name: 'module-ecommerce-ecommerce-coupon-codes-archived' }"
            >
              <v-icon left>mdi-archive</v-icon>View Archived
            </v-btn>

            <v-btn depressed color="accent" @click="$refs.CouponDialog.open()">
              <v-icon left>mdi-ticket</v-icon>Add Code
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-data-table
            :headers="tableHeaders"
            :items="coupons"
            no-data-text="No codes found"
          >
            <template v-slot:item.status="{ item }">
              <v-chip
                v-if="currentTime > changeDate(item.expires_at)"
                label
                color="error"
                text-color="white"
                >Expired</v-chip
              >

              <v-chip
                v-else-if="currentTime < changeDate(item.begins_at)"
                label
                color="warning"
                text-color="white"
                >Scheduled</v-chip
              >
              <v-chip v-else label color="success">Active</v-chip>
            </template>

            <template v-slot:item.used="{ item }">
              {{ item.codes.length }}
            </template>

            <template v-slot:item.actions="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.CouponDialog.open(item)"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    class="ml-2"
                    @click="openDelete(item)"
                  >
                    <v-icon x-small>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteCoupon.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Coupon</v-card-title>
        <v-card-text
          >Are you sure you wish to archive
          {{ deleteCoupon.code }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteCoupon.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <coupon-dialog ref="CouponDialog" />
  </div>
</template>

<script>
import CouponDialog from "./components/CouponDialog.vue";
import moment from "moment";

export default {
  components: {
    CouponDialog,
  },

  data() {
    return {
      searchTerm: "",
      currentTime: moment(new Date()).format(),
      breadcrumbs: [
        {
          text: "Ecommerce",
          disabled: true,
        },
        {
          text: "Coupon Codes",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Code", value: "code" },
        { text: "Status", value: "status" },
        { text: "Start Date Time", value: "formatted_dates.begins_at" },
        { text: "Finish Date Time", value: "formatted_dates.expires_at" },
        { text: "Used", value: "used" },
        { text: "Actions", value: "actions", align: "end" },
      ],
      deleteCoupon: {
        dialog: false,
        coupon: {},
        loading: false,
      },
    };
  },

  methods: {
    changeDate(date) {
      return moment(new Date(date)).format();
    },

    openDelete(coupon) {
      this.deleteCoupon.coupon = coupon;
      this.deleteCoupon.dialog = true;
    },

    resetDelete() {
      this.deleteCoupon.dialog = false;
      this.deleteCoupon.coupon = {};
      this.deleteCoupon.loading = false;
    },

    saveDelete() {
      this.deleteCoupon.loading = true;

      this.$store
        .dispatch("ecommerce/couponCodesStore/deleteCoupon", {
          appId: this.$route.params.id,
          couponId: this.deleteCoupon.coupon.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteCoupon.loading = false;
        });
    },
  },

  computed: {
    coupons() {
      let coupons =
        this.$store.getters["ecommerce/couponCodesStore/allCoupons"];

      if (this.searchTerm !== "") {
        coupons = coupons.filter((c) => {
          const code = c.code.toLowerCase();
          return code.includes(this.searchTerm.toLowerCase());
        });
      }

      return coupons;
    },
  },
};
</script>
