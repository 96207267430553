<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline"
        >{{ this.isEditing ? "Edit" : "Add" }} Code</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="coupon-form">
          <v-text-field
            label="Code *"
            v-model="fields.code"
            type="text"
            max="25"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('code')"
            :error-messages="errors['code']"
          ></v-text-field>
          <v-text-field
            label="Begins At *"
            v-model="fields.begins_at"
            type="datetime-local"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('begins_at')"
            :error-messages="errors['begins_at']"
          ></v-text-field>
          <v-text-field
            label="Expires At *"
            v-model="fields.expires_at"
            type="datetime-local"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('expires_at')"
            :error-messages="errors['expires_at']"
          ></v-text-field>
          <v-row>
            <v-col cols="2">
              <v-select
                label="Discount Type *"
                v-model="fields.discount_type"
                :items="types"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('discount_type')"
                :error-messages="errors['discount_type']"
              ></v-select>
            </v-col>
            <v-col cols="10">
              <v-text-field
                label="Discount Value *"
                v-model="fields.discount_amount"
                type="numeric"
                outlined
                background-color="white"
                :error="errors.hasOwnProperty('discount_amount')"
                :error-messages="errors['discount_amount']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-switch
            label="Does this code have a Limited Quota?"
            v-model="fields.is_limited"
            inset
            color="green"
            class="mt-0"
            :error="errors.hasOwnProperty('is_limited')"
            :error-messages="errors['is_limited']"
          ></v-switch>
          <v-text-field
            v-if="fields.is_limited"
            label="Limited To"
            v-model="fields.limited_to"
            type="numeric"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('limited_to')"
            :error-messages="errors['limited_to']"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="coupon-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      showPicker: false,
      showFinishPicker: false,
      isEditing: false,
      dialog: false,
      loading: false,
      coupon: {},
      fields: {
        code: null,
        begins_at: null,
        expires_at: null,
        discount_amount: null,
        discount_type: "number",
        limited_to: null,
        is_limited: false,
      },
      types: [
        { text: "£", value: "number" },
        { text: "%", value: "percent" },
      ],
      errors: {},
    };
  },

  methods: {
    open(coupon = null) {
      if (coupon !== null) {
        const beginsAt = new Date(coupon.begins_at);
        const expiresAt = new Date(coupon.expires_at);

        this.isEditing = true;
        this.coupon = coupon;
        this.fields.code = coupon.code;
        this.fields.begins_at = beginsAt.toISOString().substr(0, 16);
        this.fields.expires_at = expiresAt.toISOString().substr(0, 16);
        this.fields.discount_amount = coupon.discount_amount;
        this.fields.discount_type = coupon.discount_type;
        this.fields.limited_to = coupon.limited_to;
        this.fields.is_limited = coupon.is_limited;
      }
      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.couponId = this.coupon.id;
      }

      this.$store
        .dispatch("ecommerce/couponCodesStore/saveCoupon", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.isEditing = false;
      this.loading = false;
      this.coupon = {};
      this.fields = {
        code: null,
        begins_at: null,
        expires_at: null,
        discount_amount: null,
        discount_type: null,
        limited_to: null,
        is_limited: false,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
